











































import {
  SfHeader,
  SfLink
} from '@storefront-ui/vue';

import {
  ref,
  defineComponent,
  useFetch,
  useContext,
} from '@nuxtjs/composition-api';
import HeaderNavigation from '~/components/Header/Navigation/HeaderNavigation.vue';
import { useCategory } from '~/modules/catalog/category/composables/useCategory';
import type { CategoryTree, ProductInterface } from '~/modules/GraphQL/types';

import useMegaMenu from '~/modules/czar/megamenu/megaMenu';

export default defineComponent({
  components: {
    HeaderNavigation,
    SfHeader,
    SfLink
  },
  setup() {
    const context = useContext();
    // @ts-ignore
    const { imageProvider, magentoBaseUrl } = context.$vsf.$magento.config;
    const { useMegaMenuFun } = useMegaMenu();

    var queryVariables = {
      title: 'Top New Mega Menu'
    }
    var queryVariables_ver = {
      title: 'Mega Menu Vertical'
    }    
    let megamenu_data = [
        {
            "items_id": 611,
            "parent_id": 350,
            "title": "Used Film Cameras",
            "link": "used-equipment/used-cameras/used-film-cameras.html",
            "target": "",
            "li_class": "bold-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_611",
            "icon": "",
            "description": "Holiday Deals",
            "cols_nb": "1",
            "is_align_right": "",
            "show_title": "1",
            "content_type": "",
            "depth": "1",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 354,
            "parent_id": 350,
            "title": "Photography",
            "link": "photography",
            "target": "",
            "li_class": "other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop sm_megamenu_haschild",
            "a_id": "sm_megamenu_354",
            "icon": "",
            "description": null,
            "cols_nb": "6",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "1",
            "end_level": "5",
            "sub_menu_div_class": "sm-megamenu-child sm_megamenu_dropdown_6columns ",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 571,
            "parent_id": 354,
            "title": "Col-1",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop sm_megamenu_haschild",
            "a_id": "sm_megamenu_571",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "sm-megamenu-child sm_megamenu_dropdown_2columns ",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 379,
            "parent_id": 2,
            "title": "Col-1",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_379",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 405,
            "parent_id": 12,
            "title": "Col-1",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding border-right-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_405",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 425,
            "parent_id": 350,
            "title": "Lighting",
            "link": "lighting",
            "target": "",
            "li_class": "other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop sm_megamenu_haschild",
            "a_id": "sm_megamenu_425",
            "icon": "",
            "description": null,
            "cols_nb": "6",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "1",
            "end_level": "5",
            "sub_menu_div_class": "sm-megamenu-child sm_megamenu_dropdown_6columns ",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 519,
            "parent_id": 425,
            "title": "Col-1",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding -parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop sm_megamenu_haschild",
            "a_id": "sm_megamenu_519",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "sm-megamenu-child sm_megamenu_dropdown_2columns ",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 573,
            "parent_id": 354,
            "title": "Col-2",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop sm_megamenu_haschild",
            "a_id": "sm_megamenu_573",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "sm-megamenu-child sm_megamenu_dropdown_2columns ",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 375,
            "parent_id": 350,
            "title": "Video",
            "link": "video",
            "target": "",
            "li_class": "other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop sm_megamenu_haschild",
            "a_id": "sm_megamenu_375",
            "icon": "",
            "description": null,
            "cols_nb": "6",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "1",
            "end_level": "5",
            "sub_menu_div_class": "sm-megamenu-child sm_megamenu_dropdown_6columns ",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 380,
            "parent_id": 2,
            "title": "Col-2",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding border-left-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_380",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 406,
            "parent_id": 12,
            "title": "Col-2",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding border-right-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_406",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 488,
            "parent_id": 66,
            "title": "Col-1",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_488",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 524,
            "parent_id": 375,
            "title": "Col-1",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop sm_megamenu_haschild",
            "a_id": "sm_megamenu_524",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "sm-megamenu-child sm_megamenu_dropdown_2columns ",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 559,
            "parent_id": 350,
            "title": "Audio",
            "link": "audio",
            "target": "",
            "li_class": "other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop sm_megamenu_haschild",
            "a_id": "sm_megamenu_559",
            "icon": "",
            "description": null,
            "cols_nb": "6",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "1",
            "end_level": "5",
            "sub_menu_div_class": "sm-megamenu-child sm_megamenu_dropdown_6columns ",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 577,
            "parent_id": 354,
            "title": "Col-3",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop sm_megamenu_haschild",
            "a_id": "sm_megamenu_577",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "sm-megamenu-child sm_megamenu_dropdown_2columns ",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 592,
            "parent_id": 425,
            "title": "Col-2",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding  border-right-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop sm_megamenu_haschild",
            "a_id": "sm_megamenu_592",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "sm-megamenu-child sm_megamenu_dropdown_2columns ",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 356,
            "parent_id": 571,
            "title": "Lenses",
            "link": "photography/cameras-lenses/lenses.html",
            "target": "",
            "li_class": "other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_356",
            "icon": magentoBaseUrl+"/media/wysiwyg/mega-menu/icon/Lenses.jpg",
            "description": null,
            "cols_nb": "3",
            "is_align_right": "",
            "show_title": "1",
            "content_type": "",
            "depth": "3",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 373,
            "parent_id": 2,
            "title": "Col-3",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding border-left-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_373",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 426,
            "parent_id": 519,
            "title": "Continuous Lighting",
            "link": "http://mpex.com/lighting-studio/continuous-strobe-lighting/continuous-lighting.html",
            "target": "",
            "li_class": "other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_426",
            "icon": magentoBaseUrl+"/media/wysiwyg/mega-menu/icon/continuous-lighting.jpg",
            "description": null,
            "cols_nb": "3",
            "is_align_right": "",
            "show_title": "1",
            "content_type": "",
            "depth": "3",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 471,
            "parent_id": 12,
            "title": "Col-3",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_471",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 472,
            "parent_id": 573,
            "title": "Accessories",
            "link": "photography/accessories.html",
            "target": "",
            "li_class": "other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_472",
            "icon": magentoBaseUrl+"/media/wysiwyg/mega-menu/icon/Photo_Accessories.jpg",
            "description": null,
            "cols_nb": "3",
            "is_align_right": "",
            "show_title": "1",
            "content_type": "",
            "depth": "3",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 477,
            "parent_id": 119,
            "title": "Col-1",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_477",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 489,
            "parent_id": 66,
            "title": "Col-2",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding border-left-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_489",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 520,
            "parent_id": 425,
            "title": "Col-3",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding -parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop sm_megamenu_haschild",
            "a_id": "sm_megamenu_520",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "sm-megamenu-child sm_megamenu_dropdown_2columns ",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 527,
            "parent_id": 571,
            "title": "Digital Cameras",
            "link": "photography/cameras-lenses/digital-cameras.html",
            "target": "",
            "li_class": "other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_527",
            "icon": magentoBaseUrl+"/media/wysiwyg/mega-menu/icon/Digital_Cameras.jpg",
            "description": null,
            "cols_nb": "3",
            "is_align_right": "",
            "show_title": "1",
            "content_type": "",
            "depth": "3",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 560,
            "parent_id": 559,
            "title": "Col-1",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop sm_megamenu_haschild",
            "a_id": "sm_megamenu_560",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "sm-megamenu-child sm_megamenu_dropdown_2columns ",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 576,
            "parent_id": 573,
            "title": "Drones & Aerial Imaging",
            "link": "photography/cameras-lenses/drones-aerial-imaging.html",
            "target": "",
            "li_class": "other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_576",
            "icon": magentoBaseUrl+"/media/wysiwyg/mega-menu/icon/Drones_Accessories.jpg",
            "description": null,
            "cols_nb": "3",
            "is_align_right": "",
            "show_title": "1",
            "content_type": "",
            "depth": "3",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 579,
            "parent_id": 577,
            "title": "Film & Darkroom Supplies",
            "link": "photography/film-darkroom-supplies.html",
            "target": "",
            "li_class": "border-right-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_579",
            "icon": magentoBaseUrl+"/media/wysiwyg/mega-menu/icon/film-and-paper.jpg",
            "description": null,
            "cols_nb": "3",
            "is_align_right": "",
            "show_title": "1",
            "content_type": "",
            "depth": "3",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 594,
            "parent_id": 592,
            "title": "Flashes & On-Camera Lighting",
            "link": "http://mpex.com/lighting-studio/continuous-strobe-lighting/flashes-on-camera-lighting.html",
            "target": "",
            "li_class": "other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_594",
            "icon": magentoBaseUrl+"/media/wysiwyg/mega-menu/icon/flashes-on-camera-lighting.jpg",
            "description": null,
            "cols_nb": "3",
            "is_align_right": "",
            "show_title": "1",
            "content_type": "",
            "depth": "3",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 595,
            "parent_id": 375,
            "title": "Col-2",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding  border-right-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop sm_megamenu_haschild",
            "a_id": "sm_megamenu_595",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "sm-megamenu-child sm_megamenu_dropdown_2columns ",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 612,
            "parent_id": 350,
            "title": "Used Gear",
            "link": "shop-used-gear",
            "target": "",
            "li_class": "other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop sm_megamenu_haschild",
            "a_id": "sm_megamenu_612",
            "icon": "",
            "description": null,
            "cols_nb": "6",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "1",
            "end_level": "5",
            "sub_menu_div_class": "sm-megamenu-child sm_megamenu_dropdown_6columns ",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 376,
            "parent_id": 524,
            "title": "Drones & Aerial Imaging",
            "link": "http://mpex.com/video/drones-aerial-imaging.html",
            "target": "",
            "li_class": "other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_376",
            "icon": magentoBaseUrl+"/media/wysiwyg/mega-menu/icon/Drones_Accessories.jpg",
            "description": null,
            "cols_nb": "3",
            "is_align_right": "",
            "show_title": "1",
            "content_type": "",
            "depth": "3",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 436,
            "parent_id": 519,
            "title": "Strobe Lighting",
            "link": "http://mpex.com/lighting-studio/continuous-strobe-lighting/strobe-lighting.html",
            "target": "",
            "li_class": "other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_436",
            "icon": magentoBaseUrl+"/media/wysiwyg/mega-menu/icon/strobe-lighting.jpg",
            "description": null,
            "cols_nb": "3",
            "is_align_right": "",
            "show_title": "1",
            "content_type": "",
            "depth": "3",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 478,
            "parent_id": 119,
            "title": "Col-2",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding border-left-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_478",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 490,
            "parent_id": 66,
            "title": "Col-3",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding border-left-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_490",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 512,
            "parent_id": 350,
            "title": "Develop Film",
            "link": "http://midwestphotopix.com/film-processing-services",
            "target": "target=\"_blank\"",
            "li_class": "other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_512",
            "icon": "",
            "description": null,
            "cols_nb": "4",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "1",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 537,
            "parent_id": 375,
            "title": "Col-3",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop sm_megamenu_haschild",
            "a_id": "sm_megamenu_537",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "sm-megamenu-child sm_megamenu_dropdown_2columns ",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 561,
            "parent_id": 560,
            "title": "Location Sound",
            "link": "http://mpex.com/audio/location-sound.html",
            "target": "",
            "li_class": "other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_561",
            "icon": magentoBaseUrl+"/media/wysiwyg/mega-menu/icon/location-sound.jpg",
            "description": null,
            "cols_nb": "3",
            "is_align_right": "",
            "show_title": "1",
            "content_type": "",
            "depth": "3",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 591,
            "parent_id": 577,
            "title": "See all 17 photography Categories here",
            "link": "photography",
            "target": "",
            "li_class": "set-title-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_591",
            "icon": "",
            "description": null,
            "cols_nb": "3",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "3",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 593,
            "parent_id": 592,
            "title": "Light Modifiers",
            "link": "http://mpex.com/lighting-studio/accessories/light-modifiers.html",
            "target": "",
            "li_class": "other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_593",
            "icon": magentoBaseUrl+"/media/wysiwyg/mega-menu/icon/light-modifiersnew.jpg",
            "description": null,
            "cols_nb": "3",
            "is_align_right": "",
            "show_title": "1",
            "content_type": "",
            "depth": "3",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 596,
            "parent_id": 595,
            "title": "Cine Cameras & Lenses",
            "link": "http://mpex.com/video/cine-cameras-lenses.html",
            "target": "",
            "li_class": "other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_596",
            "icon": magentoBaseUrl+"/media/wysiwyg/mega-menu/icon/cine-cameras-lenses.jpg",
            "description": null,
            "cols_nb": "3",
            "is_align_right": "",
            "show_title": "1",
            "content_type": "",
            "depth": "3",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 598,
            "parent_id": 559,
            "title": "Col-2",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding  border-right-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop sm_megamenu_haschild",
            "a_id": "sm_megamenu_598",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "sm-megamenu-child sm_megamenu_dropdown_2columns ",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 613,
            "parent_id": 612,
            "title": "Col-1",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop sm_megamenu_haschild",
            "a_id": "sm_megamenu_613",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "sm-megamenu-child sm_megamenu_dropdown_2columns ",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 378,
            "parent_id": 524,
            "title": "Action Cameras & Accessories",
            "link": "http://mpex.com/video/action-cameras-accessories.html",
            "target": "",
            "li_class": "other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_378",
            "icon": magentoBaseUrl+"/media/wysiwyg/mega-menu/icon/action-cameras-accessories.jpg",
            "description": null,
            "cols_nb": "3",
            "is_align_right": "",
            "show_title": "1",
            "content_type": "",
            "depth": "3",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        /* {
            "items_id": 513,
            "parent_id": 350,
            "title": "Gift Cards",
            "link": "http://#",
            "target": "",
            "li_class": "other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_513",
            "icon": "",
            "description": null,
            "cols_nb": "4",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "1",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        }, */
        {
            "items_id": 562,
            "parent_id": 559,
            "title": "Col-3",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding -parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop sm_megamenu_haschild",
            "a_id": "sm_megamenu_562",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "sm-megamenu-child sm_megamenu_dropdown_2columns ",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 563,
            "parent_id": 560,
            "title": "Microphones",
            "link": "http://mpex.com/audio/microphones.html",
            "target": "",
            "li_class": "other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_563",
            "icon": magentoBaseUrl+"/media/wysiwyg/mega-menu/icon/microphones.jpg",
            "description": null,
            "cols_nb": "3",
            "is_align_right": "",
            "show_title": "1",
            "content_type": "",
            "depth": "3",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 597,
            "parent_id": 595,
            "title": "Production Equipment",
            "link": "http://mpex.com/video/production-equipment.html",
            "target": "",
            "li_class": "other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_597",
            "icon": magentoBaseUrl+"/media/wysiwyg/mega-menu/icon/production-equipment.jpg",
            "description": null,
            "cols_nb": "3",
            "is_align_right": "",
            "show_title": "1",
            "content_type": "",
            "depth": "3",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 599,
            "parent_id": 598,
            "title": "Mobile Recording",
            "link": "http://mpex.com/audio/mobile-recording.html",
            "target": "",
            "li_class": "other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_599",
            "icon": magentoBaseUrl+"/media/wysiwyg/mega-menu/icon/mobile-recordings.jpg",
            "description": null,
            "cols_nb": "3",
            "is_align_right": "",
            "show_title": "1",
            "content_type": "",
            "depth": "3",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 618,
            "parent_id": 612,
            "title": "Col-2",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding  border-right-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop sm_megamenu_haschild",
            "a_id": "sm_megamenu_618",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "sm-megamenu-child sm_megamenu_dropdown_2columns ",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 441,
            "parent_id": 520,
            "title": "See all 11 Lighting Categories Here",
            "link": "lighting",
            "target": "",
            "li_class": "set-title-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_441",
            "icon": "",
            "description": null,
            "cols_nb": "3",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "3",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 491,
            "parent_id": 132,
            "title": "Col-1",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_491",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 600,
            "parent_id": 598,
            "title": "Audio Accessories",
            "link": "http://mpex.com/audio/audio-accessories.html",
            "target": "",
            "li_class": "other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_600",
            "icon": magentoBaseUrl+"/media/wysiwyg/mega-menu/icon/audio-accessories.jpg",
            "description": null,
            "cols_nb": "3",
            "is_align_right": "",
            "show_title": "1",
            "content_type": "",
            "depth": "3",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 377,
            "parent_id": 537,
            "title": "See all 9 Video Categories Here",
            "link": "video",
            "target": "",
            "li_class": "set-title-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_377",
            "icon": "",
            "description": null,
            "cols_nb": "3",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "3",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 492,
            "parent_id": 132,
            "title": "Col-2",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding border-left border-right-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_492",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 565,
            "parent_id": 562,
            "title": "See all 10 Audio Categories Here",
            "link": "audio",
            "target": "",
            "li_class": "set-title-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_565",
            "icon": "",
            "description": null,
            "cols_nb": "3",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "3",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 601,
            "parent_id": 613,
            "title": "Shop Used Gear",
            "link": "shop-used-gear",
            "target": "",
            "li_class": "other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_601",
            "icon": magentoBaseUrl+"/media/wysiwyg/ShopUsedGear/402.jpg",
            "description": null,
            "cols_nb": "6",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "3",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 475,
            "parent_id": 215,
            "title": "Col-1",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding border-right-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_475",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 493,
            "parent_id": 132,
            "title": "Col-3",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding border-left border-right-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_493",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 498,
            "parent_id": 153,
            "title": "Col-1",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding border-right-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_498",
            "icon": "",
            "description": null,
            "cols_nb": "1",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 603,
            "parent_id": 618,
            "title": "Used Equipment Quote",
            "link": "used-equipment-quote",
            "target": "",
            "li_class": "other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_603",
            "icon": magentoBaseUrl+"/media/wysiwyg/ShopUsedGear/4386.jpg",
            "description": null,
            "cols_nb": "6",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "3",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 474,
            "parent_id": 215,
            "title": "Col-2",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_474",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 479,
            "parent_id": 218,
            "title": "Col-1",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding border-right-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_479",
            "icon": "",
            "description": null,
            "cols_nb": "1",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 494,
            "parent_id": 132,
            "title": "Col-4",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_494",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 499,
            "parent_id": 153,
            "title": "Col-2",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_499",
            "icon": "",
            "description": null,
            "cols_nb": "1",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 480,
            "parent_id": 218,
            "title": "Col-2",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_480",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 495,
            "parent_id": 170,
            "title": "Col-1",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding -parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_495",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 500,
            "parent_id": 153,
            "title": "Col-3",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding border-left-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_500",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 481,
            "parent_id": 218,
            "title": "Col-3",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding border-left-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_481",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 501,
            "parent_id": 153,
            "title": "Col-4",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding border-left-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_501",
            "icon": "",
            "description": null,
            "cols_nb": "1",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 404,
            "parent_id": 218,
            "title": "Col-4",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding border-left-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_404",
            "icon": "",
            "description": null,
            "cols_nb": "1",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 485,
            "parent_id": 282,
            "title": "Col-1",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding border-right-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_485",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 482,
            "parent_id": 15,
            "title": "Col-1",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding -parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_482",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 486,
            "parent_id": 282,
            "title": "Col-2",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding border-right-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_486",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 483,
            "parent_id": 15,
            "title": "Col-2",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding border-left border-right-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_483",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 484,
            "parent_id": 15,
            "title": "Col-3",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_484",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 487,
            "parent_id": 282,
            "title": "Col-3",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding border-right-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_487",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 496,
            "parent_id": 170,
            "title": "Col-2",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding border-left-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_496",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 506,
            "parent_id": 292,
            "title": "Col-1",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_506",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 497,
            "parent_id": 170,
            "title": "Col-3",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding border-left-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_497",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 507,
            "parent_id": 292,
            "title": "Col-2",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding border-left-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_507",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 508,
            "parent_id": 292,
            "title": "Col-3",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding border-left-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_508",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 509,
            "parent_id": 292,
            "title": "Col-4",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding border-left-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_509",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 502,
            "parent_id": 252,
            "title": "Col-1",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding -parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_502",
            "icon": "",
            "description": null,
            "cols_nb": "1",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 503,
            "parent_id": 252,
            "title": "Col-2",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding border-left-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_503",
            "icon": "",
            "description": null,
            "cols_nb": "1",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 504,
            "parent_id": 252,
            "title": "Col-3",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding border-left border-right-parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_504",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 505,
            "parent_id": 252,
            "title": "Col-4",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding -parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_505",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        },
        {
            "items_id": 510,
            "parent_id": 252,
            "title": "Col-5",
            "link": "javascript:void(0)",
            "target": "",
            "li_class": "remove-padding -parent other-toggle sm_megamenu_lv1 sm_megamenu_drop parent ",
            "a_class": "sm_megamenu_head sm_megamenu_drop ",
            "a_id": "sm_megamenu_510",
            "icon": "",
            "description": null,
            "cols_nb": "2",
            "is_align_right": "",
            "show_title": "2",
            "content_type": "",
            "depth": "2",
            "end_level": "5",
            "sub_menu_div_class": "",
            "__typename": "MenuInterface"
        }
    ];
    /* let megamenu_data_ver = []; */

    const filter_megamenudata = (data, depth) => {
      if (data) {
        var data = data.filter((item) => {
          return item.depth.match(depth);
        });
      }
      return data;
    }
    const filter_megamenudata_by_parent = (data, parent_id) => {
      if (data) {
        var data = data.filter((item22) => {
          if (item22.parent_id == parent_id) {
            return item22;
          }
        });
      }
      return data;
    }

    const remove_base_from_link = (link) => {
      link = link.replace("http://mpex.com/", "/");
      link = link.replace("https://mpex.com/", "/");
      link = link.replace("http://www.mpex.com/", "/");
      link = link.replace("https://www.mpex.com/", "/");
      
      if(Array.from(link)[0] != '/'){
        link = '/' + link;
      }      
      if(link == "/http://#" || link == "/https://#"){
        return "#";  
      }
      return link;
    }
    /* const useMegaMenuFunResult = useMegaMenuFun(queryVariables);
    megamenu_data = useMegaMenuFunResult.getMegaMenuByGroupTitle; */
    /* const useMegaMenuFunResult_ver = useMegaMenuFun(queryVariables_ver);    
    megamenu_data_ver = useMegaMenuFunResult_ver.getMegaMenuByGroupTitle; */

    

    /* 
    megamenu_data = useMegaMenuFunResult.getMegaMenuByGroupTitle;
    for (const item in useMegaMenuFunResult) {
    
      for(const item_inner in useMegaMenuFunResult[item]){
        
      }
    
  } */

    //var data_filter = megamenu_data.filter( element => element.website =="yahoo")


    const { categories: categoryList, load: categoriesListLoad } = useCategory();
    const categoryTree = ref<CategoryTree[]>([]);
    useFetch(async () => {
      await categoriesListLoad({ pageSize: 20 });

      categoryTree.value = categoryList.value?.[0]?.children
        .filter((category) => category.include_in_menu);
    });

    return {
      filter_megamenudata_by_parent,
      filter_megamenudata,
      megamenu_data,
      categoryTree,      
      remove_base_from_link
    };
  },
});
