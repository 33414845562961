export interface SortingModel {
  selected: string,
  options: SortingOption[]
}

export interface SortingOption {
  label: string,
  value: SortingOptionsValuesEnum
}

export enum SortingOptionsValuesEnum {
  POSITION = '',
  QUANTITY_AND_STOCK_STATUS_DESC = 'quantity_and_stock_status-DESC',
  NAME_ASC = 'name-ASC',
  NAME_DESC = 'name-DESC',
  BESTSELLERS_DESC = 'bestsellers-DESC',
  SAVING_DESC = 'saving-DESC',
  NEW_DESC = 'new-DESC',
  PRICE_ASC = 'price-ASC',
  PRICE_DESC = 'price-DESC',
  /* MANUFACTURER_ASC = 'manufacturer-ASC',
  MANUFACTURER_DESC = 'manufacturer-DESC', */
}

export const sortingOptions: SortingOption[] = [
  {
    label: 'Position',
    value: SortingOptionsValuesEnum.POSITION,
  },
  {
    label: 'In Stock',
    value: SortingOptionsValuesEnum.QUANTITY_AND_STOCK_STATUS_DESC,
  },
  {
    label: 'Sort: Name A-Z',
    value: SortingOptionsValuesEnum.NAME_ASC,
  },
  {
    label: 'Sort: Name Z-A',
    value: SortingOptionsValuesEnum.NAME_DESC,
  },
  {
    label: 'Best Sellers',
    value: SortingOptionsValuesEnum.BESTSELLERS_DESC,
  },
  {
    label: 'Special Price',
    value: SortingOptionsValuesEnum.SAVING_DESC,
  },
  {
    label: 'Recently Added',
    value: SortingOptionsValuesEnum.NEW_DESC,
  },
  /* {
    label: 'Sort: Price from low to high',
    value: SortingOptionsValuesEnum.PRICE_ASC,
  },
  {
    label: 'Sort: Price from high to low',
    value: SortingOptionsValuesEnum.PRICE_DESC,
  }, */
 /*  {
    label: 'Manufacturer A-Z',
    value: SortingOptionsValuesEnum.MANUFACTURER_ASC,
  },
  {
    label: 'Manufacturer Z-A',
    value: SortingOptionsValuesEnum.MANUFACTURER_DESC,
  }, */
];
