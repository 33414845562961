import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _9116fc52 = () => interopDefault(import('../pages/audio.vue' /* webpackChunkName: "pages/audio" */))
const _2d9b0418 = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _430c233e = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _655b8e8a = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _040ae095 = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _f45f0da2 = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _6f5e44d6 = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _4b4d78f1 = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _0bc69c64 = () => interopDefault(import('../pages/checkoutlogin.vue' /* webpackChunkName: "pages/checkoutlogin" */))
const _4acd93ea = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _a284c576 = () => interopDefault(import('../pages/comm-gov-edu.vue' /* webpackChunkName: "pages/comm-gov-edu" */))
const _106cc666 = () => interopDefault(import('../pages/createpassword.vue' /* webpackChunkName: "pages/createpassword" */))
const _71dd4c63 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _9b038a5e = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _572ca7f3 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _1a1f1b9c = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProductAlerts.vue' /* webpackChunkName: "" */))
const _98554de6 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _df51e15e = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _79bfd37f = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _50651211 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _539e2d0c = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _181b305d = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _096027c6 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _197d1c42 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _562c2192 = () => interopDefault(import('../pages/Faq.vue' /* webpackChunkName: "pages/Faq" */))
const _a30afda4 = () => interopDefault(import('../pages/gtm-test.vue' /* webpackChunkName: "pages/gtm-test" */))
const _32fb6fa4 = () => interopDefault(import('../pages/holiday-returns-2024.vue' /* webpackChunkName: "pages/holiday-returns-2024" */))
const _78c0848e = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "pages/Home" */))
const _a8f506ca = () => interopDefault(import('../pages/lighting.vue' /* webpackChunkName: "pages/lighting" */))
const _234d4180 = () => interopDefault(import('../pages/meet-the-team.vue' /* webpackChunkName: "pages/meet-the-team" */))
const _6e14cb84 = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _1554315e = () => interopDefault(import('../pages/photography.vue' /* webpackChunkName: "pages/photography" */))
const _7967da84 = () => interopDefault(import('../pages/returns.vue' /* webpackChunkName: "pages/returns" */))
const _65ef50ed = () => interopDefault(import('../pages/services.vue' /* webpackChunkName: "pages/services" */))
const _62f1b91c = () => interopDefault(import('../pages/shipping-policy.vue' /* webpackChunkName: "pages/shipping-policy" */))
const _6e7925d7 = () => interopDefault(import('../pages/shop-used-gear.vue' /* webpackChunkName: "pages/shop-used-gear" */))
const _11166162 = () => interopDefault(import('../pages/store.vue' /* webpackChunkName: "pages/store" */))
const _83cc9ee6 = () => interopDefault(import('../pages/track-order.vue' /* webpackChunkName: "pages/track-order" */))
const _62778dfc = () => interopDefault(import('../pages/used-equipment-quote.vue' /* webpackChunkName: "pages/used-equipment-quote" */))
const _089fc008 = () => interopDefault(import('../pages/video.vue' /* webpackChunkName: "pages/video" */))
const _bdba430c = () => interopDefault(import('../pages/catalogsearch/result.vue' /* webpackChunkName: "pages/catalogsearch/result" */))
const _7729c188 = () => interopDefault(import('../pages/order/order_print.vue' /* webpackChunkName: "pages/order/order_print" */))
const _7d5a1702 = () => interopDefault(import('../pages/order/orderDetail.vue' /* webpackChunkName: "pages/order/orderDetail" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/audio",
    component: _9116fc52,
    name: "audio___default"
  }, {
    path: "/cart",
    component: _2d9b0418,
    name: "cart___default"
  }, {
    path: "/checkout",
    component: _430c233e,
    name: "checkout___default",
    children: [{
      path: "billing",
      component: _655b8e8a,
      name: "billing___default"
    }, {
      path: "payment",
      component: _040ae095,
      name: "payment___default"
    }, {
      path: "shipping",
      component: _f45f0da2,
      name: "shipping___default"
    }, {
      path: "thank-you",
      component: _6f5e44d6,
      name: "thank-you___default"
    }, {
      path: "user-account",
      component: _4b4d78f1,
      name: "user-account___default"
    }]
  }, {
    path: "/checkoutlogin",
    component: _0bc69c64,
    name: "checkoutlogin___default"
  }, {
    path: "/Cms",
    component: _4acd93ea,
    name: "Cms___default"
  }, {
    path: "/comm-gov-edu",
    component: _a284c576,
    name: "comm-gov-edu___default"
  }, {
    path: "/createpassword",
    component: _106cc666,
    name: "createpassword___default"
  }, {
    path: "/customer",
    component: _71dd4c63,
    meta: {"titleLabel":"My Account"},
    name: "customer___default",
    children: [{
      path: "addresses-details",
      component: _9b038a5e,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___default"
    }, {
      path: "my-newsletter",
      component: _572ca7f3,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___default"
    }, {
      path: "my-product-alerts",
      component: _1a1f1b9c,
      meta: {"titleLabel":"My Product Alerts"},
      name: "customer-my-product-alerts___default"
    }, {
      path: "my-profile",
      component: _98554de6,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___default"
    }, {
      path: "my-reviews",
      component: _df51e15e,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___default"
    }, {
      path: "my-wishlist",
      component: _79bfd37f,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___default"
    }, {
      path: "order-history",
      component: _50651211,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___default"
    }, {
      path: "/reset-password",
      component: _539e2d0c,
      alias: "/customer/account/createPassword",
      name: "reset-password___default"
    }, {
      path: "addresses-details/create",
      component: _181b305d,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___default"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _096027c6,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___default"
    }, {
      path: "order-history/:orderId",
      component: _197d1c42,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___default"
    }]
  }, {
    path: "/Faq",
    component: _562c2192,
    name: "Faq___default"
  }, {
    path: "/gtm-test",
    component: _a30afda4,
    name: "gtm-test___default"
  }, {
    path: "/holiday-returns-2024",
    component: _32fb6fa4,
    name: "holiday-returns-2024___default"
  }, {
    path: "/Home",
    component: _78c0848e,
    name: "Home___default"
  }, {
    path: "/lighting",
    component: _a8f506ca,
    name: "lighting___default"
  }, {
    path: "/meet-the-team",
    component: _234d4180,
    name: "meet-the-team___default"
  }, {
    path: "/Page",
    component: _6e14cb84,
    name: "Page___default"
  }, {
    path: "/photography",
    component: _1554315e,
    name: "photography___default"
  }, {
    path: "/returns",
    component: _7967da84,
    name: "returns___default"
  }, {
    path: "/services",
    component: _65ef50ed,
    name: "services___default"
  }, {
    path: "/shipping-policy",
    component: _62f1b91c,
    name: "shipping-policy___default"
  }, {
    path: "/shop-used-gear",
    component: _6e7925d7,
    name: "shop-used-gear___default"
  }, {
    path: "/store",
    component: _11166162,
    name: "store___default"
  }, {
    path: "/track-order",
    component: _83cc9ee6,
    name: "track-order___default"
  }, {
    path: "/used-equipment-quote",
    component: _62778dfc,
    name: "used-equipment-quote___default"
  }, {
    path: "/video",
    component: _089fc008,
    name: "video___default"
  }, {
    path: "/catalogsearch/result",
    component: _bdba430c,
    name: "catalogsearch-result___default"
  }, {
    path: "/order/order_print",
    component: _7729c188,
    name: "order-order_print___default"
  }, {
    path: "/order/orderDetail",
    component: _7d5a1702,
    name: "order-orderDetail___default"
  }, {
    path: "/",
    component: _78c0848e,
    name: "home___default"
  }, {
    path: "/:slug+",
    component: _6e14cb84,
    name: "page___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
