
































































































































































































import { SfOverlay, SfHeader, SfButton, SfBadge, SfLink, SfBottomNavigation } from '@storefront-ui/vue';
import { generateLink } from '~/helpers/addBasePath';
import { computed, ref, defineComponent, useRouter, useRoute, useContext, onMounted, watch, useFetch } from '@nuxtjs/composition-api';
import { useUiHelpers, useUiState } from '~/composables';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import { useUser } from '~/modules/customer/composables/useUser';
import { useWishlistStore } from '~/modules/wishlist/store/wishlistStore';
import type { ProductInterface } from '~/modules/GraphQL/types';
import HeaderLogo from '~/components/HeaderLogo.vue';
import SvgImage from '~/components/General/SvgImage.vue';
import { FormName } from '~/modules/customer/components/LoginModal/forms/types';

export default defineComponent({
  components: {
    SfBottomNavigation,
    SfLink,
    SfHeader,
    SfOverlay,
    HeaderLogo,
    SvgImage,
    SfButton,
    SfBadge,
    CurrencySelector: () => import('~/components/CurrencySelector.vue'),
    StoreSwitcher: () => import('~/components/StoreSwitcher.vue'),
    SearchBar: () => import('~/components/Header/SearchBar/SearchBar.vue'),
    SearchResults: () => import(
      /* webpackPrefetch: true */ '~/components/Header/SearchBar/SearchResults.vue'
    ),
  },
  setup(props, { emit }) {
    let topMenues = {
      "data": {
        "getMegaMenuByGroupTitle": [
          {
            "title": "Commercial, Gov’t, & Edu",
            "link": "comm-gov-edu",
            "target": "",
            "custom_class": "",
            "__typename": "MenuInterface"
          },
          {
            "title": "Rentals",
            "link": "https://www.mpexrentals.com",
            "target": "target=\"_blank\"",
            "custom_class": "",
            "__typename": "MenuInterface"
          },
          {
            "title": "Take a Class",
            "link": "https://mpexlearningstudio.com",
            "target": "target=\"_blank\"",
            "custom_class": "",
            "__typename": "MenuInterface"
          },
          {
            "title": "Photo Lab",
            "link": "http://midwestphotopix.com",
            "target": "target=\"_blank\"",
            "custom_class": "",
            "__typename": "MenuInterface"
          },
          {
            "title": "Blog",
            "link": "http://mpex-experience.com",
            "target": "target=\"_blank\"",
            "custom_class": "",
            "__typename": "MenuInterface"
          }
        ]
      },
      "loading": false,
      "networkStatus": 7
    };
    
    const router = useRouter();
    const route = useRoute();
    const { app } = useContext();
    const { toggleCartSidebar, toggleWishlistSidebar, toggleLoginModal, toggleHelpModal, isOpenHelpPopup, openHelpPopup } = useUiState();
    const { setTermForUrl, getCatLink } = useUiHelpers();
    const { isAuthenticated, load, user } = useUser();
    const { loadTotalQty: loadCartTotalQty, cart } = useCart();
    const { loadItemsCount: loadWishlistItemsCount } = useWishlist();

    const isSearchOpen = ref(false);
    const isMobileSearchOpen = ref(false);
    const topMenuSub = ref(false);
    const productSearchResults = ref<ProductInterface[] | null>(null);

    const wishlistStore = useWishlistStore();
    const wishlistItemsQty = computed(() => wishlistStore.wishlist?.items_count ?? 0);

    const wishlistHasProducts = computed(() => wishlistItemsQty.value > 0);
    const accountIcon = computed(() => (isAuthenticated.value ? 'profile' : 'profile'));  /* for after login profile icon fill (isAuthenticated.value ? 'profile_fill' : 'profile') */

    type Form = {
      firstname: string
    };

    const form = ref<Form>({
      firstname: ''
    });

    // Fetch user data when the component is loaded
    useFetch(async () => {
      if (isAuthenticated && user.value === null) {
        await load();
      }
      if (isAuthenticated.value && user.value != null) {
        form.value.firstname = user.value.firstname;
      }
    });

    watch(isAuthenticated, async (newVal) => {
      if (newVal) {
        await load();
        if (user.value) {
          form.value.firstname = user.value.firstname;
        }
      } else {
        form.value.firstname = '';
      }
    });

    const activeIcon = ref(true);

    const {
      toggleMobileMenu,
    } = useUiState();

    const handleAccountClick = async () => {
      if (isAuthenticated.value) {
        await router.push(app.localeRoute({ name: 'customer-order-history' }));
      } else {
        toggleLoginModal();
      }
    };
    
    const changeForm = (formToNavigateTo: FormName) => {
      emit('change-form', formToNavigateTo);
    };
    onMounted(async () => {
      if (app.$device.isDesktop) {
        await loadCartTotalQty();
        // eslint-disable-next-line promise/catch-or-return
        await loadWishlistItemsCount();
      }
      if(route?.value?.query?.accountcreate){
        toggleLoginModal();
        changeForm('register');
      }
    });

    const loadCategoryMenu = async () => {
      toggleMobileMenu();
    };

    const mobilesearchtoggle = () => {
      isMobileSearchOpen.value = !isMobileSearchOpen.value;
    };

    const topMenuToggle = () => {
      topMenuSub.value = !topMenuSub.value;
    };

    return {
      activeIcon,
      topMenuToggle,
      topMenuSub,
      mobilesearchtoggle,
      isMobileSearchOpen,
      loadCategoryMenu,
      topMenues,
      generateLink,
      accountIcon,
      cartTotalItems: computed(() => cart.value?.total_quantity ?? 0),
      // categoryTree,
      getCatLink,
      handleAccountClick,
      isAuthenticated,
      isSearchOpen,
      productSearchResults,
      setTermForUrl,
      toggleCartSidebar,
      toggleWishlistSidebar,
      wishlistHasProducts,
      wishlistItemsQty,
      toggleHelpModal,
      openHelpPopup,
      isOpenHelpPopup,
      form,
    };
  },
});
