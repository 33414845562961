





















import { defineComponent, useRoute, useRouter, onMounted, ref } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { SfButton, SfLoader } from '@storefront-ui/vue';
import type { NuxtError } from '@nuxt/types';

export default defineComponent({
  components: {
    SfButton,
    SfLoader
  },
  props: {
    error: {
      type: Object as PropType<NuxtError>,
      required: true,
    },
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const loading = ref(false);
    onMounted(() => {
      // Only handle 404 errors
      // @ts-ignore
      if (props.error.statusCode === 404) {
        const currentPath = route.value.path;
        loading.value = true;
        // Prevent redirecting if already on the target search page
        if (currentPath.startsWith('/catalogsearch/result')) {
          console.log("Already on search results page, skipping redirection.");
          return;
        }

        console.log("Original Path:", currentPath);

        // Extract the slug from the path
        const slug = currentPath.replace('/', '').replace('.html', '');
        const searchUrl = `/catalogsearch/result/?term=${encodeURIComponent(slug)}`;
        
        console.log("Redirecting to:", searchUrl);

        // Redirect to the search page
        router.push({ path: searchUrl });
      }
    });

    return {
      loading
    };
  },
});
