








import { defineComponent } from '@nuxtjs/composition-api';
import { useCmsBlocks } from "~/components/czar/customTickerBar/cmsBlocks";
import { storeToRefs } from 'pinia';
import { useRouter } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'TextTickerBlock',
  components: {

  },
  props: {
    
  },
  setup(props) {
    const cmsBlocks = useCmsBlocks();
    const { items } = storeToRefs(cmsBlocks);
    var queryVariables = { identifiers: ["custom-ticker"] };
    cmsBlocks.fetchCmsBlock(queryVariables);
    const router = useRouter();

    const handleLinkClick = (event) => {
      const anchor = event.target.closest('a');
      if (anchor) {
        event.preventDefault();
        const href = anchor.getAttribute('href');
        if (href) {
          router.push(href);
        }
      }
    };

    return {
      items,
      handleLinkClick
    };
  },
});

