import { defineStore } from 'pinia';
import { RoutableInterface } from '~/modules/GraphQL/types';

interface PageState {
    routeData: RoutableInterface | null;
    routeDataMeta: RoutableInterface | null;
}

export const usePageStore = defineStore('page', {
    state: (): PageState => ({
        routeData: null,
        routeDataMeta: null,
    }),

    actions: {
        setRouteData(data: RoutableInterface | null) {
            //this.routeData = data;
            this.routeDataMeta = data;
        },

        // ✅ Reset store state before each request
        $reset() {
            this.routeData = null;
            this.routeDataMeta = null;
        },
    },
});
