export default ({ app }) => {  
  if (process.client) {

    // Initialize Google Analytics
    window.dataLayer = window.dataLayer || [];
    function gtag() {
    window.dataLayer.push(arguments);
    }
    window.gtag = gtag;
    
    gtag('js', new Date());
    gtag('config', 'G-EYHDSMCYF4');

    // Load Klaviyo script
    (function () {
      if (!window.klaviyo) {
        window._klOnsite = window._klOnsite || [];
        try {
          window.klaviyo = new Proxy({}, {
            get: function (n, i) {
              return "push" === i
                ? function () {
                    window._klOnsite.push(Array.prototype.slice.call(arguments));
                  }
                : function () {
                    var n = arguments.length,
                      o = new Array(n);
                    for (var w = 0; w < n; w++) o[w] = arguments[w];
                    var t = "function" == typeof o[o.length - 1] ? o.pop() : void 0,
                      e = new Promise(function (n) {
                        window._klOnsite.push([i].concat(o, [function (i) {
                          t && t(i), n(i);
                        }]));
                      });
                    return e;
                  };
            },
          });
        } catch (n) {
          window.klaviyo = window.klaviyo || [];
          window.klaviyo.push = function () {
            window._klOnsite.push(Array.prototype.slice.call(arguments));
          };
        }
      }
    })();
  }
};