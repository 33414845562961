import { Logger } from '~/helpers/logger';
import { Cart } from '~/modules/GraphQL/types';
import { VsfContext } from '~/composables/context';
import applyCouponToCartGGL from '~/modules/checkout/queries/amastyApplyCouponToCart.gql';

export const applyCouponCommand = {
  
  execute: async (context: VsfContext, {
    currentCart,
    couponCode,
    currentCode,
    customQuery = { applyCouponToCart: 'applyCouponToCart' },
    customHeaders = {},
  }) => {
    Logger.debug('[Magento]: Apply coupon on cart', {
      couponCode,
      currentCart,
    });

    // const { data, errors } = await context.$magento.api.applyCouponToCart({
    //   cart_id: currentCart.id,
    //   coupon_code: couponCode,
    // }, customQuery, customHeaders);
    
    
    const {data,errors} = await context.$magento.api.customQuery({
      query: applyCouponToCartGGL,
      queryVariables: {cart_id: currentCart.id,coupon_code: [couponCode]},
      customHeaders: customHeaders,             
    });
        
    

    Logger.debug('[Result]:', { data, errors });

    return {
      // @ts-ignore
      updatedCart: data.amastyApplyCouponToCart?.cart as unknown as Cart,
      updatedCoupon: { code: couponCode },
      errors
    };
  },
};
  